import { FC, MouseEventHandler, PropsWithChildren, SVGProps } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { LoadingIndicator } from '@components/atoms/LoadingIndicator'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

type Props = {
  componentType?: 'button' | 'a'
  href?: string
  anchorTarget?: '_self' | '_blank'
  hierarchy?:
    | 'primary'
    | 'secondary-gray'
    | 'secondary-color'
    | 'tertiary-gray'
    | 'tertiary-color'
    | 'link-gray'
    | 'link-color'
    | 'gray'
    | 'none'
  size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  buttonType?: 'button' | 'submit' | 'reset'
  shape?: 'round' | 'square'
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  isLoading?: boolean
  leftIcon?: SVGProps<SVGElement>
  rightIcon?: SVGProps<SVGElement>
}

export const Button2: FC<PropsWithChildren<Props>> = ({
  children,
  componentType = 'button',
  href = '',
  anchorTarget = '_self',
  hierarchy = 'primary',
  size = 'md',
  buttonType = 'button',
  shape = 'round',
  disabled = false,
  onClick,
  isLoading = false,
  leftIcon = null,
  rightIcon = null,
}) => {
  const containerCNs = classNames(
    styles.container,
    {
      [styles[hierarchy]]: true, // hierarchy の名前でクラスを付与
    },
    {
      [styles[size]]: true, // size の名前でクラスを付与
    },
    {
      [styles[shape]]: true, // size の名前でクラスを付与
    },
    {
      [styles.disabled]: disabled,
    },
    {
      [styles.loading]: isLoading && componentType === 'button', // a タグにはインジケーターを表示しない
    }
  )

  const contentCNs = classNames(styles.content, {
    [styles.loading]: isLoading && componentType === 'button',
  })

  if (componentType === 'a') {
    return (
      <Link href={sanitizeUrl(href)}>
        <a className={containerCNs} target={anchorTarget}>
          <div className={contentCNs}>
            {leftIcon && <div className={styles.icon}>{leftIcon}</div>}
            <div className={styles.text}>{children}</div>
            {rightIcon && <div className={styles.icon}>{rightIcon}</div>}
          </div>
        </a>
      </Link>
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={buttonType} className={containerCNs} onClick={onClick} disabled={disabled}>
      <div className={contentCNs}>
        {leftIcon && <div className={styles.icon}>{leftIcon}</div>}
        <div className={styles.text}>{children}</div>
        {rightIcon && <div className={styles.icon}>{rightIcon}</div>}
      </div>
      {isLoading && (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator size='small' color='white' />
        </div>
      )}
    </button>
  )
}
